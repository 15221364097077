define("discourse/plugins/stemaway-project-generation/discourse/connectors/topic-navigation/ai-mentor", ["exports", "@ember/service", "@ember/component", "@ember/runloop", "discourse/lib/ajax"], function (_exports, _service, _component, _runloop, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    router: (0, _service.inject)(),
    evaluationService: (0, _service.inject)('ai-mentor-service'),
    currentUser: (0, _service.inject)(),
    chatVisible: false,
    messages: [],
    userInput: '',
    chatCollapsed: false,
    systemEvaluation: null,
    showEvaluationPopup: false,
    stemawayUser: null,
    isFinalEvaluation: false,
    isLoadingResponse: false,
    currentQuestionIndex: 0,
    userFeedback: [],
    followUpInProgress: false,
    currentFollowUpQuestion: null,
    questions: null,
    evaluationParameters: {
      technicalProficiency: 0,
      problemSolving: 0,
      innovation: 0,
      adaptability: 0,
      initiative: 0,
      communication: 0
    },
    init() {
      this._super(...arguments);
      this.evaluationService.checkIfTopicInCodeAlongCategory().then(() => {
        if (!this.get('evaluationService.displayCodeAlongButton')) {
          return;
        }
        this.topicId = this.get('router.currentRoute.parent.params.id');
        const chatVisible = localStorage.getItem('chatVisible') === 'true';
        const chatCollapsed = true;
        this.setProperties({
          chatVisible: chatVisible,
          chatCollapsed: chatCollapsed
        });
        if (this.get('currentUser')) {
          (0, _ajax.ajax)('/users/stemaway.json').then(response => {
            this.set('stemawayUser', response.user);
          });
        }
        this.checkGreeting();
      }).catch(error => {
        console.error('Error checking code along category:', error);
      });
    },
    checkGreeting() {
      this.scrollToBottom();
      if (this.evaluationService.firstGreeting) {
        let message = "Hello! Type 'evaluate' to start the evaluation process.";
        this.get('messages').pushObject({
          html: message,
          sender: 'ai',
          isHtml: true
        });
        this.evaluationService.setFirstGreeting(false);
      }
    },
    scrollToBottom() {
      (0, _runloop.scheduleOnce)('afterRender', this, function () {
        const chatMessagesContainer = document.querySelector('.chat-messages-topics-mcq');
        if (chatMessagesContainer) {
          chatMessagesContainer.lastElementChild.scrollIntoView({
            behavior: 'smooth'
          });
        }
      });
    },
    makeAPIRequest(prompt, model) {
      this.set('isLoadingResponse', true);
      const encodedPrompt = btoa(unescape(encodeURIComponent(prompt)));
      const encodedModel = btoa(model);
      const firstName = this.get('currentUser.name').split(' ')[0];
      return (0, _ajax.ajax)("/stemaway-project-generation/make-api-request", {
        type: 'POST',
        contentType: 'application/json',
        data: JSON.stringify({
          prompt: encodedPrompt,
          model: encodedModel,
          mcq: false,
          first_name: firstName
        })
      }).finally(() => {
        this.set('isLoadingResponse', false);
      });
    },
    startIntroduction() {
      const introductionText = `
      You will be asked a series of questions designed to evaluate your understanding of the material.
      Type 'start' to begin the evaluation.
    `;
      this.get('messages').pushObject({
        html: introductionText,
        sender: 'ai',
        isHtml: true
      });
    },
    sendNotification() {
      return (0, _ajax.ajax)('/stemaway-project-generation/send-evaluation-notification', {
        type: 'POST'
      }).then(() => {
        console.log('notification sent eval');
      }).catch(error => {
        console.error('Error sending notification:', error);
      });
    },
    processEvaluation() {
      this.set('isLoadingResponse', true);
      (0, _ajax.ajax)(`/stemaway-project-generation/fetch-topic-content/${this.topicId}`).then(response => {
        if (response.error) {
          throw new Error(response.error);
        }
        let topicContent = response.content;
        return this.makeAPIRequest(`Generate exactly 10 medium-hard difficulty questions based on the following content to evaluate a student's understanding. The questions should be detailed, conversational, and engaging:\n\n${topicContent}`, 'main');
      }).then(response => {
        if (response) {
          const content = decodeURIComponent(escape(atob(response)));
          console.log('content:', content);
          const questions = this.parseQuestions(content);
          this.evaluateUserOnQuestions(questions);
        } else {
          this.get('messages').pushObject({
            text: "We encountered an issue. Please try again later.",
            sender: 'ai'
          });
        }
        this.scrollToBottom();
      }).catch(error => {
        console.error('Error during evaluation process:', error);
        this.get('messages').pushObject({
          text: `Error: ${error.message}`,
          sender: 'system'
        });
      }).finally(() => {
        this.set('isLoadingResponse', false);
      });
    },
    parseQuestions(content) {
      const questionBlocks = content.split(/\n(?=\d+\.\s\*\*)/).map(block => block.trim());
      return questionBlocks.map((block, index) => {
        const questionText = block.replace(/^\d+\.\s\*\*\w+.*\*\*:\s*-?\s*/, '').trim();
        const isInstruction = questionText.toLowerCase().includes("here are") || questionText.toLowerCase().includes("instructions");
        if (isInstruction) {
          return null;
        }
        const cleanQuestionText = questionText.split(/(?:\.\s+|;\s+)/).filter(sentence => !sentence.toLowerCase().includes("encourage") && !sentence.toLowerCase().includes("fostering")).join('. ');
        return {
          index: index + 1,
          question: cleanQuestionText,
          options: []
        };
      }).filter(Boolean).map((question, index) => {
        question.index = index + 1;
        return question;
      });
    },
    evaluateUserOnQuestions(questions) {
      this.set('questions', questions);
      this.set('currentQuestionIndex', 0);
      this.set('followUpInProgress', false);
      this.set('currentFollowUpQuestion', null);
      this.askNextQuestion();
    },
    askNextQuestion() {
      const question = this.questions[this.currentQuestionIndex];
      if (question) {
        const feedbackMessage = this.generateQuestionText(question);
        this.get('messages').pushObject({
          html: feedbackMessage,
          sender: 'ai',
          isHtml: true
        });
        this.scrollToBottom();
      } else {
        this.provideConsolidatedFeedback();
      }
    },
    recordUserResponse(response) {
      const currentQuestion = this.questions[this.currentQuestionIndex];
      const topicContent = this.get('questions').map(q => q.question).join("\n");
      const questionText = this.get('followUpInProgress') ? `Follow-up to question "${currentQuestion.question}": ${this.get('currentFollowUpQuestion')}` : currentQuestion.question;
      const firstName = this.get('currentUser.name').split(' ')[0];
      const personalizedPrompt = `The student's name is ${firstName}. The response is "${response}".`;
      this.set('isLoadingResponse', true);
      this.makeAPIRequest(`Evaluate the student's response at the level of a college student given the following context and question and respond to them in first person:

      Context:
      ${topicContent}

      Question:
      ${questionText}

      Student's Response:
      ${personalizedPrompt}

      Provide BRIEF, constructive feedback on the student's response in MAX 8 lines. Focus on both the content and the structure of the response, mentioning particular concepts or details that were well-addressed or missed. Aim to offer unique insights or suggestions that would help the student deepen their understanding of the topic.

      Please avoid generic feedback and instead tailor your response to the specific content of the student's answer. Conclude with a critical assessment of their performance and provide evaluation parameters (out of 100) in EXACTLY the following format:

      ### Feedback
      {feedback}
      
      ### Evaluation Parameters
      Technical Proficiency: {score}/100
      Problem Solving: {score}/100
      Innovation: {score}/100 
      Adaptability: {score}/100
      Initiative: {score}/100
      Communication: {score}/100`, 'main').then(feedbackResponse => {
        const feedbackData = this.processFeedback(decodeURIComponent(escape(atob(feedbackResponse))));
        this.userFeedback.push({
          question: questionText,
          userResponse: response,
          feedback: feedbackData.feedback,
          scores: feedbackData.scores
        });
        this.updateEvaluationParameters(feedbackData.scores);
        this.get('messages').pushObject({
          html: feedbackData.feedback,
          sender: 'ai',
          isHtml: true
        });
        this.scrollToBottom();
        this.set('isLoadingResponse', true);
        setTimeout(() => {
          this.set('isLoadingResponse', false);
          if (this.get('followUpInProgress')) {
            this.set('followUpInProgress', false);
            this.set('currentFollowUpQuestion', null);
            this.incrementProperty('currentQuestionIndex');
            this.askNextQuestion();
          } else {
            const shouldAskFollowUp = Math.random() < 0.2;
            if (shouldAskFollowUp) {
              this.set('followUpInProgress', true);
              this.askFollowUpQuestion(currentQuestion, response);
            } else {
              this.incrementProperty('currentQuestionIndex');
              this.askNextQuestion();
            }
          }
        }, Math.random() * 500 + 1500);
      }).catch(error => {
        console.error('Error during feedback process:', error);
        this.get('messages').pushObject({
          text: `Error: ${error.message}`,
          sender: 'system'
        });
        this.incrementProperty('currentQuestionIndex');
        this.askNextQuestion();
      }).finally(() => {
        this.set('isLoadingResponse', false);
      });
    },
    askFollowUpQuestion(originalQuestion, userResponse) {
      const firstName = this.get('currentUser.name').split(' ')[0];
      const personalizedPrompt = `The student's name is ${firstName}. The original question is "${originalQuestion.question}". The student's response is "${userResponse}".`;
      this.set('isLoadingResponse', true);
      this.makeAPIRequest(`Generate a follow-up question to delve deeper into the student's understanding based on the original question and their previous response. Only provide the follow-up question.${personalizedPrompt}`, 'main').then(followUpResponse => {
        const followUpQuestion = this.extractFollowUpQuestion(decodeURIComponent(escape(atob(followUpResponse))));
        const followUpMessage = `<p><strong>Follow-up:</strong> ${followUpQuestion}</p>`;
        this.get('messages').pushObject({
          html: followUpMessage,
          sender: 'ai',
          isHtml: true
        });
        this.scrollToBottom();
        this.set('currentFollowUpQuestion', followUpQuestion);
        this.waitForUserResponse().then(followUpUserResponse => {
          this.set('isLoadingResponse', true);
          setTimeout(() => {
            this.set('isLoadingResponse', false);
            this.recordUserResponse(followUpUserResponse);
          }, Math.random() * 500 + 1500);
        });
      }).catch(error => {
        console.error('Error generating follow-up question:', error);
        this.incrementProperty('currentQuestionIndex');
        this.askNextQuestion();
      }).finally(() => {
        this.set('isLoadingResponse', false);
      });
    },
    extractFollowUpQuestion(responseText) {
      const match = responseText.match(/(?:\b(?:\w+\s*){1,10}:\s*)?("?.+?\?")/);
      return match ? match[1] : responseText.split('.').pop().trim();
    },
    waitForUserResponse() {
      return new Promise(resolve => {
        const captureResponse = event => {
          const response = event.target.value.trim();
          if (response) {
            resolve(response);
            document.querySelector('textarea').removeEventListener('keydown', captureResponse);
          }
        };
        document.querySelector('textarea').addEventListener('keydown', event => {
          if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            captureResponse(event);
          }
        });
      });
    },
    generateQuestionText(question) {
      return `
      <h3>Question ${question.index}</h3>
      <p>${question.question}</p>
    `;
    },
    processFeedback(feedbackText) {
      console.log('processing feedback:', feedbackText);
      const feedbackMatch = feedbackText.match(/### Feedback\s*(.*?)\s*### Evaluation Parameters/);
      const feedback = feedbackMatch ? feedbackMatch[1].trim() : feedbackText.split('### Evaluation Parameters')[0].replace('### Feedback', '').trim();
      const scores = {
        technicalProficiency: this.extractScore(feedbackText, 'Technical Proficiency'),
        problemSolving: this.extractScore(feedbackText, 'Problem Solving'),
        innovation: this.extractScore(feedbackText, 'Innovation'),
        adaptability: this.extractScore(feedbackText, 'Adaptability'),
        initiative: this.extractScore(feedbackText, 'Initiative'),
        communication: this.extractScore(feedbackText, 'Communication')
      };
      return {
        feedback: feedback,
        scores: scores
      };
    },
    extractScore(feedbackText, parameter) {
      const regex = new RegExp(`${parameter.replace(/\s/g, '\\s*')}:\\s*(\\d+)\\/100`, 'i');
      const match = regex.exec(feedbackText);
      return match ? parseInt(match[1], 10) : null;
    },
    updateEvaluationParameters(scores) {
      this.set('evaluationParameters.technicalProficiency', scores.technicalProficiency);
      this.set('evaluationParameters.problemSolving', scores.problemSolving);
      this.set('evaluationParameters.innovation', scores.innovation);
      this.set('evaluationParameters.adaptability', scores.adaptability);
      this.set('evaluationParameters.initiative', scores.initiative);
      this.set('evaluationParameters.communication', scores.communication);
      console.log('updated evaluation parameters:', this.evaluationParameters);
    },
    provideConsolidatedFeedback() {
      console.log(this.userFeedback);
      const totalScores = {
        technicalProficiency: 0,
        problemSolving: 0,
        innovation: 0,
        adaptability: 0,
        initiative: 0,
        communication: 0
      };
      const feedbackCount = this.userFeedback.length;
      this.userFeedback.forEach(feedback => {
        totalScores.technicalProficiency += feedback.scores.technicalProficiency || 0;
        totalScores.problemSolving += feedback.scores.problemSolving || 0;
        totalScores.innovation += feedback.scores.innovation || 0;
        totalScores.adaptability += feedback.scores.adaptability || 0;
        totalScores.initiative += feedback.scores.initiative || 0;
        totalScores.communication += feedback.scores.communication || 0;
      });
      const averageScores = {
        technicalProficiency: Math.round(totalScores.technicalProficiency / feedbackCount),
        problemSolving: Math.round(totalScores.problemSolving / feedbackCount),
        innovation: Math.round(totalScores.innovation / feedbackCount),
        adaptability: Math.round(totalScores.adaptability / feedbackCount),
        initiative: Math.round(totalScores.initiative / feedbackCount),
        communication: Math.round(totalScores.communication / feedbackCount)
      };
      console.log('average scores:', averageScores);
      const collectedFeedback = this.userFeedback.map(f => f.feedback).join('\n\n');
      const prompt = `ONLY provide a final consolidated detailed summary in MAX 6 paragraphs based on the following feedbacks and write it in a way that you are speaking directly to the student. The feedback of each object is in "feedback". Group the follow-ups to a question under the same paragraph. You will be judging at the level of a college student. Address the user directly. Avoid unnecessary beginning sentences. Format your response as follows: {Provide a comprehensive summary here. Use numbering (1,2,3 etc.) where appropriate for better understanding the feedback.} Give the output in proper formatting and spacing. The feedbacks are : ${collectedFeedback}`;
      this.makeAPIRequest(prompt, 'main').then(finalSummaryResponse => {
        const finalSummary = decodeURIComponent(escape(atob(finalSummaryResponse)));
        const formattedSummary = finalSummary.replace(/(\d+)\.\s+/g, '<br><strong>$1.</strong> ').replace(/(\.\s+)/g, '$1<br>').trim();
        let feedbackHtml = `
              <h4>Final Summary:</h4>
              <p>${formattedSummary}</p>
              <h4>Final Scores:</h4>
              <p>Technical Proficiency: ${averageScores.technicalProficiency}/100<br>
              Problem Solving: ${averageScores.problemSolving}/100<br>
              Innovation: ${averageScores.innovation}/100<br>
              Adaptability: ${averageScores.adaptability}/100<br>
              Initiative: ${averageScores.initiative}/100<br>
              Communication: ${averageScores.communication}/100</p>
          `;
        this.get('messages').pushObject({
          html: feedbackHtml,
          sender: 'ai',
          isHtml: true
        });
        (0, _ajax.ajax)('/stemaway-project-generation/save-evaluation', {
          type: 'POST',
          data: {
            technical_proficiency: averageScores.technicalProficiency,
            problem_solving: averageScores.problemSolving,
            innovation: averageScores.innovation,
            adaptability: averageScores.adaptability,
            initiative: averageScores.initiative,
            communication: averageScores.communication,
            feedback: finalSummary.trim(),
            topic_id: this.topicId
          }
        }).then(() => {
          console.log("evaluation data saved");
          this.setProperties({
            userFeedback: [],
            currentQuestionIndex: 0,
            followUpInProgress: false,
            currentFollowUpQuestion: null,
            questions: null,
            evaluationParameters: {
              technicalProficiency: 0,
              problemSolving: 0,
              innovation: 0,
              adaptability: 0,
              initiative: 0,
              communication: 0
            }
          });
        }).catch(error => {
          console.error('Error saving final evaluation data:', error);
        });
        this.sendNotification().then(() => {
          console.log("notification sent");
        });
        this.scrollToBottom();
      }).catch(error => {
        console.error('Error generating final summary:', error);
        this.get('messages').pushObject({
          text: `Error: ${error.message}`,
          sender: 'system'
        });
      });
    },
    actions: {
      startChat() {
        this.checkGreeting();
        this.set('chatVisible', true);
        this.set('chatCollapsed', false);
        localStorage.setItem('chatVisible', this.get('chatVisible'));
      },
      toggleCollapseChat() {
        this.checkGreeting();
        this.toggleProperty('chatCollapsed');
        localStorage.setItem('chatCollapsed', this.get('chatCollapsed'));
      },
      toggleChat() {
        this.checkGreeting();
        this.toggleProperty('chatVisible');
        localStorage.setItem('chatVisible', this.get('chatVisible'));
      },
      sendMessage() {
        let message = this.get('userInput').trim();
        if (!message) {
          return;
        }
        this.get('messages').pushObject({
          text: message,
          sender: 'user'
        });
        this.scrollToBottom();
        this.set('userInput', '');
        if (this.isEvaluateMode && this.questions) {
          this.recordUserResponse(message);
        } else if (message.toLowerCase() === "evaluate") {
          this.set('isEvaluateMode', true);
          this.startIntroduction();
        } else if (message.toLowerCase() === "start" && this.isEvaluateMode) {
          this.processEvaluation();
        } else {
          this.get('messages').pushObject({
            text: "Please type 'evaluate' to start the evaluation process.",
            sender: 'system'
          });
        }
      },
      handleTextareaKeyDown(event) {
        if (event.key === 'Enter' && !event.shiftKey) {
          event.preventDefault();
          this.send('sendMessage');
        }
      },
      toggleEvaluationPopup() {
        this.toggleProperty('showEvaluationPopup');
      },
      updateUserInput(value) {
        this.set('userInput', value);
      }
    }
  });
});